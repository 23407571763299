var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"auth-wrapper auth-wrapper-register auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"150px"},attrs:{"src":_vm.logoUrl,"alt":"Payrite"}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Account aanmaken ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"r":""},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"companyOrConsumer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"companyOrConsumer","value":1},on:{"change":function($event){return _vm.cleanForm(1)}},model:{value:(_vm.signup.business),callback:function ($$v) {_vm.$set(_vm.signup, "business", $$v)},expression:"signup.business"}},[_vm._v(" Ik ben een freelancer ")]),_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"companyOrConsumer","value":2},on:{"change":function($event){return _vm.cleanForm(2)}},model:{value:(_vm.signup.business),callback:function ($$v) {_vm.$set(_vm.signup, "business", $$v)},expression:"signup.business"}},[_vm._v(" Ik ben een opdrachtgever ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.signup.business === 2)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Zoek op bedrijf, KVK-nummer of trefwoord","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"cocNumberCompany","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.signup.cocNumber),expression:"signup.cocNumber"}],attrs:{"type":"hidden","name":"cocNumberCompany"},domProps:{"value":(_vm.signup.cocNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.signup, "cocNumber", $event.target.value)}}}),_c('vue-autosuggest',{ref:"autocomplete",class:errors.length > 0 ? 'error':'',attrs:{"suggestions":_vm.suggestions,"input-props":_vm.inputProps,"get-suggestion-value":_vm.getSuggestionValue},on:{"selected":function($event){return _vm.onSelected($event.item)},"input":_vm.fetchResults},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(((suggestion.item.companyName) + " - " + (suggestion.item.cocNumber))))])]),_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(((suggestion.item.street) + " " + (suggestion.item.houseNumber) + (suggestion.item.houseNumberAddition) + ", " + (suggestion.item.postalCode) + " " + (suggestion.item.city))))])])],1)]}}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("Dit veld is verplicht, selecteer een bedrijf. ")]):_vm._e()]}}],null,false,878901256)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Bedrijfsmail","label-for":"companyEmail"}},[_c('validation-provider',{attrs:{"name":"companyEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"companyEmail","state":errors.length > 0 ? false:null,"name":"userEmail ","placeholder":"john@example.nl"},model:{value:(_vm.signup.companyEmail ),callback:function ($$v) {_vm.$set(_vm.signup, "companyEmail", $$v)},expression:"signup.companyEmail "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,945449627)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"userEmail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"userEmail ","placeholder":"john@example.nl"},model:{value:(_vm.signup.userEmail ),callback:function ($$v) {_vm.$set(_vm.signup, "userEmail", $$v)},expression:"signup.userEmail "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Telefoonnummer","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","type":"text"},model:{value:(_vm.signup.phone),callback:function ($$v) {_vm.$set(_vm.signup, "phone", $$v)},expression:"signup.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Voornaam","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false:null,"name":"firstName"},model:{value:(_vm.signup.firstName),callback:function ($$v) {_vm.$set(_vm.signup, "firstName", $$v)},expression:"signup.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Tussenvoegsel","label-for":"middleName"}},[_c('validation-provider',{attrs:{"name":"middleName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middleName","state":errors.length > 0 ? false:null,"name":"middleName"},model:{value:(_vm.signup.middleName),callback:function ($$v) {_vm.$set(_vm.signup, "middleName", $$v)},expression:"signup.middleName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Achternaam","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false:null,"name":"lastName"},model:{value:(_vm.signup.lastName),callback:function ($$v) {_vm.$set(_vm.signup, "lastName", $$v)},expression:"signup.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Wachtwoord","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.signup.password),callback:function ($$v) {_vm.$set(_vm.signup, "password", $$v)},expression:"signup.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Bevestig wachtwoord"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.signup.cPassword),callback:function ($$v) {_vm.$set(_vm.signup, "cPassword", $$v)},expression:"signup.cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-1",attrs:{"show":_vm.showSuccesMessageCreateAccount,"variant":"success"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" Je hebt een email ontvangen voor het activeren van je account, na het activeren kan je inloggen en gebruik maken van ons platform. ")],1)]),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[(_vm.spinner)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" Aanmaken ")],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Heb je al een account? ")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Terug naar login ")],1)],1)],1)],1)]),[_c('p',{staticClass:"clearfix mb-0 login-footer"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }