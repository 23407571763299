import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    forgotPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}passwordforgot`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchCocRegister(ctx, coc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}kvk?q=${coc.q}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}passwordreset`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activateAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}activate`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getInvite(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}invite/${data.token}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptInvite(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}invite/${data.token}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    signUp(ctx, data) {
      let url = ''

      if (data.business === 1) {
        url = 'freelancer'
      }

      if (data.business === 2) {
        url = 'company'
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}signup/${url}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
