<template>
  <div>
    <div class="auth-wrapper auth-wrapper-register auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Register v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <b-img
              :src="logoUrl"
              alt="Payrite"
              style="width:150px"
            />
          </b-link>

          <b-card-title class="mb-1">
            Account aanmaken
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form
              r
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="companyOrConsumer"
                      rules="required"
                    >
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          class="mt-0"
                          v-model="signup.business"
                          name="companyOrConsumer"
                          :value="1"
                          @change="cleanForm(1)"
                        >
                          Ik ben een freelancer
                        </b-form-radio>
                        <b-form-radio
                          class="mt-0"
                          v-model="signup.business"
                          name="companyOrConsumer"
                          :value="2"
                          @change="cleanForm(2)"
                        >
                          Ik ben een opdrachtgever
                        </b-form-radio>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="signup.business === 2"
              >
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    label="Zoek op bedrijf, KVK-nummer of trefwoord"
                    label-for="company"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cocNumberCompany"
                      rules="required"
                    >
                      <input type="hidden" name="cocNumberCompany" v-model="signup.cocNumber"/>
                      <vue-autosuggest
                        ref="autocomplete"
                        v-model="query"
                        :suggestions="suggestions"
                        :input-props="inputProps"
                        @selected="onSelected($event.item)"
                        :get-suggestion-value="getSuggestionValue"
                        @input="fetchResults"
                        :class="errors.length > 0 ? 'error':''"
                      >
                        <template slot-scope="{suggestion}">
                          <b-row>
                            <b-col
                              cols="12"
                            >
                              <span class="my-suggestion-item">{{`${suggestion.item.companyName} - ${suggestion.item.cocNumber}` }}</span>
                            </b-col>
                            <b-col
                              cols="12"
                            >
                              <span class="my-suggestion-item">{{
                                  `${suggestion.item.street} ${suggestion.item.houseNumber}${suggestion.item.houseNumberAddition}, ${suggestion.item.postalCode} ${suggestion.item.city}`
                                }}</span>
                            </b-col>
                          </b-row>
                        </template>
                      </vue-autosuggest>
                      <small
                        class="text-danger"
                       v-if="errors.length > 0"
                      >Dit veld is verplicht, selecteer een bedrijf.
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    label="Bedrijfsmail"
                    label-for="companyEmail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="companyEmail"
                      rules="required|email"
                    >
                      <b-form-input
                        id="companyEmail"
                        v-model="signup.companyEmail "
                        :state="errors.length > 0 ? false:null"
                        name="userEmail "
                        placeholder="john@example.nl"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="userEmail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="signup.userEmail "
                        :state="errors.length > 0 ? false:null"
                        name="userEmail "
                        placeholder="john@example.nl"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Telefoonnummer"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="phone"
                        v-model="signup.phone"
                        :state="errors.length > 0 ? false:null"
                        name="phone"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Voornaam"
                    label-for="firstName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="firstName"
                      rules="required"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="signup.firstName"
                        :state="errors.length > 0 ? false:null"
                        name="firstName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tussenvoegsel"
                    label-for="middleName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="middleName"
                    >
                      <b-form-input
                        id="middleName"
                        v-model="signup.middleName"
                        :state="errors.length > 0 ? false:null"
                        name="middleName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-form-group
                    label="Achternaam"
                    label-for="lastName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="lastName"
                      rules="required"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="signup.lastName"
                        :state="errors.length > 0 ? false:null"
                        name="lastName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Wachtwoord"
                    label-for="reset-password-new"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-new"
                          v-model="signup.password"
                          :type="password1FieldType"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          name="reset-password-new"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password1ToggleIcon"
                            @click="togglePassword1Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- confirm password -->
                  <b-form-group
                    label-for="reset-password-confirm"
                    label="Bevestig wachtwoord"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-confirm"
                          v-model="signup.cPassword"
                          :type="password2FieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false:null"
                          name="reset-password-confirm"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                            @click="togglePassword2Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
<!--                <b-col-->
<!--                  cols="12"-->
<!--                >-->
<!--                  <b-form-group>-->
<!--                    <b-form-checkbox-->
<!--                      id="register-privacy-policy"-->
<!--                      v-model="status"-->
<!--                      name="checkbox-1"-->
<!--                    >-->
<!--                      Ik ga akkoord met de algemene voorwaarden en privacy statement-->
<!--                    </b-form-checkbox>-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
              </b-row>
              <b-alert
                :show="showSuccesMessageCreateAccount"
                v-height-fade.appear
                variant="success"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  Je hebt een email ontvangen voor het activeren van je account, na het activeren kan je inloggen en gebruik maken van ons platform.
                </div>
              </b-alert>
              <b-button
                variant="primary"
                block
                type="submit"
              >
                <b-spinner
                  v-if="spinner"
                  small
                  class="mr-1"
                />
                Aanmaken
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Heb je al een account? </span>
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon"/>
              Terug naar login
            </b-link>
          </b-card-text>

        </b-card>
        <!-- /Register v1 -->
      </div>
    </div>
    <template>
      <p class="clearfix mb-0 login-footer">
      </p>
    </template>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import {
  email,
  required,
  integer,
  length,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import authenticationStoreModule from '@/views/pages/authentication/authenticationStoreModule'
import { onUnmounted } from '@vue/composition-api'
import { VueAutosuggest } from 'vue-autosuggest'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BFormRadio,
    BRow,
    BCol,
    BAlert,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
      integer,
      length,
      signup: {
        business: 1,
        companyName: null,
        cocNumber: null,
        houseNumber: null,
        postalCode: null,
        city: null,
        street: null,
        userEmail: null,
        companyEmail: null,
        cPassword: '',
        password: '',
        firstName: null,
        middleName: null,
        lastName: null,
        phone: null,
      },
      signupBlankData: {
        business: 1,
        companyName: null,
        cocNumber: null,
        houseNumber: null,
        postalCode: null,
        city: null,
        street: null,
        userEmail: null,
        companyEmail: null,
        cPassword: '',
        password: '',
        firstName: null,
        middleName: null,
        lastName: null,
        phone: null,
      },
      password1FieldType: 'password',
      password2FieldType: 'password',
      inputProps: {
        id: 'autosuggest__input_ajax',
        class: 'form-control',
        placeholder: 'Zoek bedrijf in KvK register',
      },
      limit: 10,
      query: '',
      results: [],
      timeout: null,
      debounceMilliseconds: 250,
      suggestions: [],
      showSuccesMessageCreateAccount: false,
      spinner: false,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      return this.logoImg
    },
  },
  created() {
    if (this.$route.query.type) {
      this.signup.business = +this.$route.query.type
    }
  },
  methods: {
    onSelected(value) {
      this.signup.companyName = value.companyName
      this.signup.cocNumber = value.cocNumber
      this.signup.houseNumber = value.houseNumber
      this.signup.houseNumberAddition = value.houseNumberAddition
      this.signup.postalCode = value.postalCode
      this.signup.city = value.city
      this.signup.street = value.street
      this.signup.name = value.companyName
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store
          .dispatch('auth-register/searchCocRegister', {
            q: query,
            sortBy: 'name',
          })
          .then(response => {
            this.suggestions = [{
              data: response.data,
            }]
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kvk register',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
              },
            })
          })
      }, this.debounceMilliseconds)
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.companyName
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.registerForm.validate()
        .then(success => {
          if (success) {
            this.spinner = true
            this.showSuccesMessageCreateAccount = false
            store.dispatch('auth-register/signUp', this.signup)
              .then(response => {
                this.spinner = false

                if (response.data.success) {
                  this.resetForm()
                  this.showSuccesMessageCreateAccount = true
                }
                if (!response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Account aanmaken',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
              })
              .catch(() => {
                this.spinner = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Account aanmaken',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Er is een fout opgetreden, probeer later het nog een keer.',
                  },
                })
              })
          }
        })
    },
    resetForm() {
      this.signup = JSON.parse(JSON.stringify(this.signupBlankData))
      this.$refs.registerForm.reset()
    },
    cleanForm(business) {
      const formData = this.signupBlankData
      formData.business = business
      this.signup = JSON.parse(JSON.stringify(formData))
      this.$refs.registerForm.reset()
    },
  },
  setup() {
    const SIGNUP_APP_STORE_MODULE_NAME = 'auth-register'

    // Register module
    if (!store.hasModule(SIGNUP_APP_STORE_MODULE_NAME)) store.registerModule(SIGNUP_APP_STORE_MODULE_NAME, authenticationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SIGNUP_APP_STORE_MODULE_NAME)) store.unregisterModule(SIGNUP_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper-register {
  background: url("../../../assets/images/pages/register.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
<style lang="scss" scoped>
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 600px !important;
}

.auth-wrapper.auth-v1 {
  align-items: flex-start !important;
}

.error {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
